<template>
	<v-layout fill-height column>
		<v-flex changelog shrink justify-center align-center></v-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'HeadWay',
	data () {
		return {
			HEADWAY_WIDGET_URL: '//cdn.headwayapp.co/widget.js',
			widget: undefined,
			config: {
				selector: '.changelog',
				account: '72ezNx',
				callbacks: {
					onWidgetReady: widget => {
						this.widget = widget
					},
					onShowWidget: () => this.$parent.$emit('close:header-items', [this.id])
				}
			}
		}
	},
	mounted () {
		let headwayScript = document.createElement('script')
		headwayScript.async = true
		headwayScript.setAttribute('src', this.HEADWAY_WIDGET_URL)
		document.head.appendChild(headwayScript)
		const waitForHeadway = () => {
			if (window['Headway']) {
				window.Headway.init(this.config)
			} else {
				setTimeout(() => waitForHeadway(), 100)
			}
		}
		waitForHeadway()
	}
}
</script>
